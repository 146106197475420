<template>
  <div>
    <!-- 单人受理 -->
    <el-form class="params-wrap" size="small" :inline="true" :model="queryParams">
      <el-form-item>
        <el-input v-model="queryParams.account" clearable placeholder="请输入学号" @change="refreshB()"></el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model="queryParams.idCard" clearable placeholder="请输入身份证号"></el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model="queryParams.mealMobile" clearable placeholder="请输入融合号码"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="success" icon="el-icon-search"  @click="refreshB()">搜索</el-button>
      </el-form-item>
      <el-form-item class="fr">
        <div class="btn-wrap cfx">
          <el-button type="success" size="small" icon="el-icon-plus" @click="handleForm(false,afterHandle())" v-if="OA.includes('user:userStatus:add')">添加</el-button>
          <!-- <el-button type="warning" size="small" icon="el-icon-position" @click="handleOut" v-if="OA.includes('user:userStatus:derive')">导出</el-button> -->
        </div>
      </el-form-item>
    </el-form>
    <div class="content-wrap">
      <!--列表-->
      <el-table :data="storeList" border stripe style="width: 100%"
        ref="table">
        <el-table-column prop="account" show-overflow-tooltip label="学号/工号"></el-table-column>
        <el-table-column show-overflow-tooltip label="名称">
          <template slot-scope="scope" v-if="scope.row.extend">
            <span>{{ scope.row.name ? scope.row.name : scope.row.extend.userName }}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip label="类型">
          <template slot-scope="scope">
            <span v-if="scope.row.userType == 102001001">学生</span>
            <span v-else-if="scope.row.userType == 102001002">老师</span>
            <span v-else>其他</span>
          </template>
        </el-table-column>
         <el-table-column show-overflow-tooltip label="实名认证" >
          <template slot-scope="scope">
            <span v-if="scope.row.auth == 2">已认证</span>
            <span v-else>未认证</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip label="学校" width="130">
          <template slot-scope="scope" v-if="scope.row.extend">
            <span>{{ scope.row.extend.schoolName ? scope.row.extend.schoolName : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="phone" show-overflow-tooltip label="注册手机" width="100" >
          <template slot-scope="scope" v-if="scope.row.extend">
            <span>{{ scope.row.extend.mobile ? scope.row.extend.mobile : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="mealMobile" show-overflow-tooltip label="套餐号码" width="100" ></el-table-column>
        <el-table-column prop="idCard" show-overflow-tooltip label="身份证" width="150"></el-table-column>
        <el-table-column prop="mealId" show-overflow-tooltip label="套餐编号" ></el-table-column>
        <el-table-column prop="mealName" show-overflow-tooltip label="套餐" width="150"></el-table-column>
        <el-table-column prop="startDate" show-overflow-tooltip label="套餐开始" ></el-table-column>
        <el-table-column prop="endDate" show-overflow-tooltip label="套餐结束"></el-table-column>
        <el-table-column prop="" show-overflow-tooltip label="最后操作">
          <template slot-scope="scope" v-if="scope.row.extend">
            <span>{{ scope.row.extend.updUserName ? scope.row.extend.updUserName : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="remark" show-overflow-tooltip label="备注"></el-table-column>
      </el-table>
      <div style="margin-top: 20px">
        <el-button size="small" class="handle" :disabled="!storeList.length" icon="el-icon-search" @click="handleDetails(userDetail)">详情</el-button>
        <el-button size="small" type="primary" :disabled="!storeList.length" @click="handleForm(true, userDetail,afterHandleForm)" icon="el-icon-edit" title="编辑"  v-if="OA.includes('user:userStatus:editor')">编辑</el-button>
        <el-button size="small" type="danger" :disabled="!storeList.length" @click="handleDelete({id: userDetail.id})" icon="el-icon-delete" title="删除\销户" v-if="OA.includes('user:userStatus:del')">删除\销户</el-button>
        <el-button size="small" class="handle" :disabled="!storeList.length" @click="handleReset(userDetail.id,userDetail.userId)" v-if="OA.includes('user:userStatus:reset')">重置密码</el-button>
        <el-button size="small" class="handle" :disabled="!storeList.length" @click="handleOpen(userDetail)" v-if="OA.includes('user:userStatus:single')">单宽办理</el-button>
        <!-- <el-button size="small" class="handle" @click="handleOpen(userDetail.id, userDetail.mealId)">单宽办理</el-button> -->
        <el-button size="small" class="handle" :disabled="!storeList.length" @click="handleFuse(userDetail)" v-if="OA.includes('user:userStatus:fuse')">融合办理</el-button>
        <el-button size="small" class="handle" :disabled="!storeList.length" @click="handleStop(userDetail.id, userDetail.mealId)" v-if="OA.includes('user:userStatus:stop')">报停</el-button>
        <el-button size="small" class="handle" :disabled="!storeList.length" @click="handleAgain(userDetail.id, userDetail.mealId)" v-if="OA.includes('user:userStatus:again')">复通</el-button>
        <el-button size="small" class="handle" :disabled="!storeList.length" @click="handleHistory(userDetail.account)" v-if="OA.includes('user:userStatus:single')">操作记录</el-button>
        <el-button size="small" class="handle" :disabled="!storeList.length" @click="handleResetMpPwd(userDetail.account)" v-if="OA.includes('user:userStatus:reset')">重置公众号登录密码</el-button>
      </div>
    </div>

    <!-- 编辑界面 -->
    <el-dialog :title="isEditor ? '编辑用户' : '添加用户'" :visible.sync="aVisible" width="500px" :close-on-click-modal="false">
      <el-form label-width="110px" ref="formFileds" :model="form" :rules="formRules" size="small">
        <el-form-item label="所属学校：" prop="schoolId">
          <el-select clearable filterable v-model="form.schoolId">
            <el-option label="安徽科技学院凤阳校区" :value="1"></el-option>
            <el-option label="蚌埠学院" :value="2"></el-option>
            <el-option label="安徽科技学院龙湖校区" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学号或工号：" prop="account">
          <el-input v-model="form.account" placeholder="请输入学号或工号" clearable></el-input>
        </el-form-item>
        <el-form-item label="姓名：" prop="name" v-if="!isEditor">
          <el-input v-model="form.name" placeholder="请输入姓名" clearable></el-input>
        </el-form-item>
        <el-form-item label="手机号：" prop="mealMobile" v-if="!isEditor">
          <el-input v-model="form.mealMobile" placeholder="请输入手机号" clearable></el-input>
        </el-form-item>
        <el-form-item label="身份证号：" prop="idCard">
          <el-input v-model="form.idCard" placeholder="请输入身份证号" clearable></el-input>
        </el-form-item>
        <el-form-item label="套餐时间：" prop="startDate" v-if="!!isEditor">
          <el-date-picker size="small" v-model="timeRange" @change="getTimeRange($event,'startDate','endDate')" type="daterange" value-format="yyyy-MM-dd" :picker-options="recentOptions" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" align="right"></el-date-picker>
        </el-form-item>
        <el-form-item label="状态：" prop="status" v-if="!!isEditor">
          <el-radio-group v-model="form.status">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="类型：" prop="userType" v-if="!isEditor">
          <el-radio-group v-model="form.userType">
            <el-radio :label="102001002">老师</el-radio>
            <el-radio :label="102001003">其他</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注：" prop="remark" v-if="!isEditor">
          <el-input v-model="form.remark" placeholder="请输入备注" clearable></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="aVisible = false">取消</el-button>
        <el-button size="small" type="primary" @click="isEditor ? submitForm(editorApi) : submitForm(insertApi)">保存</el-button>
      </div>
    </el-dialog>
    
    <!-- 详情界面 -->
    <el-dialog title="学校详情" :visible.sync="bVisible" width="500px" :close-on-click-modal="false">
      <el-form label-width="100px" ref="detailsFileds" :model="details" size="small">
        <el-form-item label="学校名称：" v-if="details.extend">
          <span v-text="details.extend.schoolName"></span>
        </el-form-item>
        <el-form-item label="学号：">
          <span v-text="details.account"></span>
        </el-form-item>
        <el-form-item label="开始时间：">
          <span v-text="details.startDate"></span>
        </el-form-item>
        <el-form-item label="结束时间：">
          <span v-text="details.endDate"></span>
        </el-form-item>
        <el-form-item label="身份证号：">
          <span v-text="details.idCard"></span>
        </el-form-item>
        <!-- <el-form-item label="校园网密码：">
          <span v-text="details.pwd"></span>
        </el-form-item> -->
        <el-form-item label="备注：">
          <span v-text="details.remark"></span>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 单宽办理 -->
    <el-dialog title="单宽办理" :visible.sync="openVisible" width="500px">
      <el-form label-width="100px" ref="formOpenFileds" :model="formOpen" :rules="formOpenRules" size="small">
        <el-form-item label="选择单宽：" prop="schoolId" style="width:80%;">
          <el-select clearable filterable v-model="formOpen.mealId">
            <el-option v-for="item in openOptions" :label="item.name" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button size="small" @click="openVisible = false,formOpen.mealId = ''">取消</el-button>
          <el-button size="small" type="primary" @click="submitOpen">提交</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 融合办理 -->
    <el-dialog title="融合办理" :visible.sync="fuseVisible" width="500px">
      <el-form label-width="100px" ref="formFuseFileds" :model="formFuse" :rules="formFuseRules" size="small">
        <el-form-item label="选择融合：" prop="mealId" style="width:80%;">
          <el-select clearable filterable v-model="formFuse.mealId">
            <el-option v-for="(item,index) in fuseOptions" :label="item.name" :value="item.id" :key="index"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="融合号码：" prop="mealMobile">
          <el-input v-model="formFuse.mealMobile" placeholder="请输入融合号码" clearable style="width:75%;"></el-input>
        </el-form-item>
        <el-form-item label="移动密码：" prop="cmccPwd" v-if="formFuse.mealId==106">
          <el-input v-model="formFuse.cmccPwd" placeholder="请输入移动密码" clearable style="width:75%;"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="small" @click="fuseVisible = false,formFuse.mealId = '',formFuse.mealMobile = '',formFuse.cmccPwd = ''">取消</el-button>
          <el-button size="small" type="primary" @click="submitFuse">提交</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>


    <!--操作记录-->
    <el-dialog title="操作记录" :visible.sync="historyVisible" width="1000px">      
      <el-table :data="historyList" border stripe style="width: 100%"  ref="table">
        <el-table-column
          prop="mealId"
          label="套餐id"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="content"
          label="套餐"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="orderType"
          label="操作类型"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.orderType === 1">套餐充值</span>
            <span v-if="scope.row.orderType === 2">套餐续订</span>
            <span v-if="scope.row.orderType === 3">套餐升级</span>
            <span v-if="scope.row.orderType === 4">套餐报停</span>
            <span v-if="scope.row.orderType === 5">套餐复通</span>
            <span v-if="scope.row.orderType === 6">套餐变更</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="updUserId"
          label="操作人"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{scope.row.extend.updUserName || scope.row.extend.addUserName}}
          </template>
        </el-table-column>          
        <el-table-column
          prop="addTime"
          label="操作时间"
          :show-overflow-tooltip="true"
        >
        </el-table-column>        
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PagedTable from "@/components/common/PagedTable";
// import BaseImport from "@/components/common/BaseImport";
import AreaTree from "@/components/common/AreaTree";
import pageMixin from "@/mixins/pageMixin";
import provinces from "@/mixins/provinces";
import datePicker from "@/mixins/datePicker";
import download from "@/mixins/download";
import { BaseUrl } from "@/common/config";
import { log, mergeObject } from "@/common/utils";
export default {
  name: "userStatus",
  components: {
    PagedTable,
    AreaTree,
    // BaseImport
  },
  mixins: [pageMixin, provinces, download, datePicker],
  data() {
    return {
      historyVisible: false,
      historyParams: {
        pageNum: 1,
        pageSize: 10,
        account: '',
      },
      historyList: [],
      openVisible: false,
      fuseVisible: false,      
      schoolOptions: [],
      BaseUrl,      
      queryParams: {
        pageNum: 1,
        pageSize: 1,
        account: '',
        idCard: null,
        mealMobile:'',
      },
      schoolType: [],
      form:{
        id: "",
        schoolId: "",
        userId: "",
        idCard: "",
        account: "",
        mealMobile: '',
        name: "",
        mealId: null,
        startDate:'',
        endDate:"",
        meal_mobile:"",
        meal_sta: null,
        status: null,
        userType: '',
        remark: '',
      },
      formOpen: {
        id: '',
        mealId: '',
      },
      dataBtn: [
        {},
      ],
      formOpenRules: {},
      formFuse: {
        id: '',
        mealId: '',
        mealMobile: '',
        cmccPwd:'',
      },
      userDetail: {
        id:null,
        extend: {},
      },
      formFuseRules: {},
      openOptions: [],
      fuseOptions: [],
      details:{
        id: null,
        addTime: '',
        updTime: '',
        idCard: null,
        account: null,
        pwd: '',
        name: '',
        mealId: '',
        startDate: '',
        endDate: '',
        mealMobile: '',
        remark: '',
        extend:{}
      },
      formRules: {
        schoolId: [{ required: true, message: "请选择学校", trigger: "change" }],
        name: [{ required: true, message: "请选择姓名", trigger: "blur" }],
        mealMobile: [{ required: true, message: "请输入融合号码", trigger: "blur" }],
        account: [{ required: true, message: "请输入学号或工号", trigger: "blur" }],
        idCard: [{ required: true, message: "请输入身份证号", trigger: "blur" }],
        userType: [{ required: true, message: "请选择用户类型", trigger: "blur" }],
      },

      insertApi: "userNetAdd",
      editorApi: "userNetEdit",
      deleteApi: "userNetDelete",
      loadApi: "userNetLoad",
      pageApi: "",
    };
  },
  computed: {
    ...mapState(["OA"]),
    ...mapState("userStatus", ["storeList", "storeTotal"]),
  },
  methods: {
    ...mapActions("userStatus",["userNetList", "userNetAdd", "userNetLoad", "userNetEdit", "userNetDelete", "userNetResetPwd","userNetResetMpPwd", "userNetOpen", 'userNetMealSelect','userNetRestore','userNetStop', 'userNetHistory']),
    ...mapActions("commonOptions", ["getSchoolCombo"]),
    handleFormB(){},
    // 时间
    async refreshB(){ //列表查询
      this.tLoading = true
      this.savePageParams({
        pageName: this.pageName,
        params: this.queryParams
      });
      try {
        let res =  await this.userNetList(this.queryParams)
        this.userDetail = res.list[0]
        // console.log(this.userDetail)
      }catch(e){
        // log(e)
      }finally {
        this.tLoading = false;
      }
    },
    timeRangeChange(val, index){
        this.gameTime[index].startDate = val ? val[0] : ''
        this.gameTime[index].endDate = val ? val[1] : ''
    },
    async afterHandleForm(row){
        this.timeRange = [ row.startDate, row.endDate ]
    },
    afterHandle(){
      delete this.form.id
      delete this.form.endDate
      delete this.form.mealId
      delete this.form.meal_mobile
      delete this.form.meal_sta
      delete this.form.startDate
      delete this.form.meal_sta
      delete this.form.userId
      delete this.form.status
    },
    /** 重置密码 */
    handleReset(id,userId) {
      this.$confirm('此操作将重置密码, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then( async () => {
        try{
          let res = await this.userNetResetPwd({id,userId})
          this.$message({
            type: 'success',
            message: res
          });
        }catch (error){
          this.$message.error("重置密码失败")
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消重置'
        });          
      });
    },
    /** 重置公众号登录密码 */
    handleResetMpPwd(account) {
      this.$confirm('此操作将重置公众号登录密码, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then( async () => {
        try{
          let res = await this.userNetResetMpPwd({account})
          this.$message({
            type: 'success',
            message: res
          });
        }catch (error){
          this.$message.error("重置密码失败")
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消重置'
        });          
      });
    },
    async handleOpen(row) {
      this.formOpen.id = row.id
      this.openVisible = true
      let res = await this.userNetMealSelect({userId:row.userId,mergeFlag:0})
      // console.log(res)
      this.openOptions = res
    },
    async handleFuse (row){
      this.formFuse.id = row.id
      this.fuseVisible = true
      let res = await this.userNetMealSelect({userId:row.userId,mergeFlag:0})
      let resB = await this.userNetMealSelect({userId:row.userId,mergeFlag:1})
      this.openOptions = res
      this.fuseOptions = resB
    },
    async handleHistory(account) {
      console.log("account",account)
      this.historyParams.account = account
      this.historyVisible = true
      let res = await this.userNetHistory({account:account})
      console.log("操作记录======",res.list)
      this.historyList = res.list
    },
    /** 开通单宽 */
    submitOpen() {
      this.$confirm('此操作将开通单宽, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then( async () => {
          try{
            let res = await this.userNetOpen({...this.formOpen})
            this.$message({
              type: 'success',
              message: res
            });
          }catch (error){
            this.$message.error("开通单宽失败")
          }finally{
            this.openVisible = false
            this.formOpen.mealId = ''
            this.refreshB()
          }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消开通单宽'
          });          
          this.openVisible = false
          this.formOpen.mealId = ''
        })
    },
    /** 开通融合 */
    submitFuse() {
      if(!this.formFuse.mealMobile){
        return this.$message.error("融合号码不能为空")
      }
      if(this.formFuse.mealId == 106 && !this.formFuse.cmccPwd){
        return this.$message.error("移动密码不能为空")
      }
      this.$confirm('此操作将开通融合, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then( async () => {
          try{
            let res = await this.userNetOpen({...this.formFuse})
            this.$message({
              type: 'success',
              message: res
            });
          }catch (error){
            this.$message.error("开通融合失败")
          }finally{
            this.fuseVisible = false
            this.formFuse.mealId = ''
            this.formFuse.mealMobile = ''
            this.refreshB()
          }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消开通融合'
          });          
          this.fuseVisible = false
          this.formFuse.mealId = ''
          this.formFuse.mealMobile = ''
        })
    },
    /** 报停 */
    handleStop() {
      this.$confirm('此操作将报停, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then( async () => {
          try{
            let res = await this.userNetStop({id:this.userDetail.id})
            this.$message({
              type: 'success',
              message: res
            });
          }catch (error){
            this.$message.error("报停失败")
          }finally{
            this.openVisible = false
            this.refreshB()
          }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消报停'
          });          
          this.openVisible = false
        })
    },
    /** 复通 */
    handleAgain() {
      this.$confirm('此操作将复通, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then( async () => {
          try{
            let res = await this.userNetRestore({id:this.userDetail.id})
            this.$message({
              type: 'success',
              message: res
            });
          }catch (error){
            this.$message.error("复通失败")
          }finally{
            this.openVisible = false
            this.refreshB()
          }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消复通'
          });          
          this.openVisible = false
        })
    },

    handleOut() {
      this.$confirm('此操作将导出表格, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then( async () => {
        // try{
        //   let res = await this.userNetResetPwd({id,userId})
          this.$message({
            type: 'success',
            message: "导出成功"
          });
        // }catch (error){
        //   this.$message.error("重置密码失败")
        // }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消导出'
        });          
      });
    },
    setSelection(list){
      // log(list)
    },
    handleQuery(data) {
      if ((data.id + "").length === 9) { this.queryParams.province = data.id; this.queryParams.city = null;
        this.queryParams.area = null; }
      if ((data.id + "").length === 12) { this.queryParams.city = data.id; this.queryParams.area = null; }
      if ((data.id + "").length === 15) { this.queryParams.area = data.id; }
      if ((data.id + "").length <9) { this.queryParams.school = data.id; }
      this.refreshB();
    },
  },
  async mounted() {
    if(this.getPageParams(this.$options.name))
    mergeObject(this.queryParams, this.getPageParams(this.$options.name))
    this.schoolType = await this.commonDictGetDictFPid({pid: 104001})
    this.schoolOptions = await this.getSchoolCombo({})
    this.refreshB()
  },
};
</script>
